import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../Hooks/authHook';
import { Paper } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { statusColors } from '../Constants/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(2)
    }
  },
  loginButton: {
    marginLeft: 'auto'
  }
}));

export default function LoginPage() {
  let { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const auth = useAuth();
  const [values, setValues] = useState({ username: '', password: '' });
  const [msg, setMsg] = useState('');
  let { from } = location.state || { from: { pathname: '/' } };

  useEffect(() => {
    if (auth.user) {
      history.replace(from);
    }
  }, [auth]);

  const login = (event) => {
    event.preventDefault();
    auth.signin(values.username, values.password, (m) => {
      setMsg(m);
      if (m === 'good') history.replace(from);
    });
  };

  const [open_reset_dialog, setOpenResetDialog] = React.useState(false);
  const openResetDialog = () => {
    setOpenResetDialog(true);
  };
  const closeResetDialog = () => {
    setOpenResetDialog(false);
  };

  const classes = useStyles();
  
  
  
  return (
    <Container maxWidth="sm">  
    
      <Paper mt={4} component={Box}>
        <ResetDialog open={open_reset_dialog} handleClose={closeResetDialog} />
        <>Login should be possible again shortly, we apologize for any inconvinience.</>
        <form onSubmit={login} className={classes.root}>
          <TextField
            autoFocus
            id="username"
            label={t('username')}
            type="text"
            fullWidth
            onChange={(event) => setValues({ ...values, username: event.target.value })}
          />
          <TextField
            id="password"
            label={t('password')}
            type="password"
            fullWidth
            onChange={(event) => setValues({ ...values, password: event.target.value })}
          />
          <Box mt={2} display="flex" color={statusColors['bad']}>
            {msg}
          </Box>
          <Box mt={2} display="flex">
            <Button onClick={openResetDialog}>{t('reset_password')}</Button>
            <Button
              className={classes.loginButton}
              variant="contained"
              type="submit"
              color="primary"
            >
              {t('login')}
            </Button>
          </Box>
          <Box mt={2} display="flex">
            <Link style={{ color: statusColors.good }} to="/register">
              {t('to_register')}
            </Link>
          </Box>
        </form>
      </Paper>
    </Container>
  );
}

function ResetDialog({ open, handleClose }) {
  const [username, setUsername] = React.useState(null);
  let { t } = useTranslation();

  const validateForm = (event) => {
    if (username) {
      var json_request = {};
      json_request['username'] = username;

      fetch('/api/reset_password', { method: 'POST', body: JSON.stringify(json_request) }).then(
        (response) => {
          if (response.ok) {
            alert('You received a mail with a password-reset-link.');
          } else if (response.status === 401) {
            alert(
              t('r_pw_err_unathenticated_1') + ' ' + username + ' ' + t('r_pw_err_unathenticated_2')
            );
          } else if (response.status === 400) {
            alert(t('r_pw_err_no_email_1') + ' ' + username + ' ' + t('r_pw_err_no_email_2'));
          } else if (response.status === 500) {
            alert(t('r_pw_err_server_error'));
          }
        }
      );
      handleClose();
    } else {
      handleClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('reset_password')}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="serial"
          label={t('username')}
          type="text"
          fullWidth
          onChange={(event) => setUsername(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={validateForm} color="primary">
          {t('reset')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
